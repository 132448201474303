import React from 'react';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {
  AppContext,
  ToastService,
  TwoAction,
  TwoEntityComponent,
  TwoEntityPanel,
  TwoTimeline,
  TwoTimelineItem,
} from 'two-app-ui';
import './Company.scss';
import {Company, CompanyAggregate, QueryParameter, TimeLineEvent} from 'two-core';
import {Subscription} from 'rxjs';
import {MessageService} from 'two-app-ui';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import TleService from '../../services/TleService';
import {Toast} from 'primereact/toast';
import EditCompanyDialog from './EditCompanyDialog';
import {ProgressSpinner} from 'primereact/progressspinner';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import CompanyDetail from './CompanyDetail';
import CompanyOrders from './CompanyOrders';
import CompanyContacts from './CompanyContacts';
import CompanyLocations from './CompanyLocations';
import CompanySubDealers from './CompanySubDealers';
import CompanyFitForComponent from './CompanyFitForComponent';

interface RouteProps {
  id: string;
}

interface State {
  loadingCompany: boolean;
  loadingSecondaryView: boolean;
  company: Company | undefined;
  events: TimeLineEvent[];
  items: TwoTimelineItem[];
  showEditDialog: boolean;
}

class CompanyComponent extends React.Component<RouteComponentProps<RouteProps>, State> {
  static contextType = AppContext;
  companiesService: CompaniesService | null = null;
  tleService: TleService | null = null;
  toastService: ToastService | null = null;

  subscription: Subscription = new Subscription();

  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<RouteProps>) {
    super(props);

    this.state = {
      loadingCompany: false,
      loadingSecondaryView: false,
      company: undefined,
      events: [],
      items: [],
      showEditDialog: false,
    };
    this.toast = React.createRef();

    this.hideEditDialog = this.hideEditDialog.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.tleService = this.context.tleService;
    this.toastService = this.context.toastService;

    const id = this.props.match.params.id;
    this.loadCompany(id);
    this.loadEvents(id);

    this.subscription = MessageService.getMessage().subscribe(message => {
      if (message === messages.companyUpdated) {
        this.loadCompany(id);
      }
    });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  loadCompany(id: string) {
    this.setState({loadingCompany: true});

    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );

    const companyAggregate: CompanyAggregate[] = [
      'sales_rep_user',
      'parent_company',
      'contacts',
      'company_contacts',
      'fit_for',
      'shared_locations',
      'shared_location_locations',
    ];

    const params: QueryParameter = {
      filters: filters,
      aggregate: companyAggregate,
    };
    this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const company = (data.records as Company[])[0];
        this.setState({company: company, loadingCompany: false});
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, customer load failed, please try again.');
        console.error(error);
        this.setState({loadingCompany: false});
      });
  }

  loadEvents(id: string) {
    this.setState({loadingSecondaryView: true});

    const filters: string[] = [
      JSON.stringify({
        field: 'entity_type',
        value: 'company',
      }),
      JSON.stringify({
        field: 'entity_id',
        value: id,
      }),
    ];
    const orderBys = JSON.stringify({field: 'recorded_at', direction: 'DESC'});
    const params: QueryParameter = {
      filters: filters,
      orderBys: [orderBys],
      aggregate: true,
    };
    this.tleService
      ?.getTimeLineEvents(params)
      .then(data => {
        const events = data.records as TimeLineEvent[];

        const items = events.map(event => {
          const item: TwoTimelineItem = {event: event};
          return item;
        });

        this.setState({
          events: events,
          loadingSecondaryView: false,
          items: items,
        });
      })
      .catch(() => {
        this.toastService?.showError(this.toast, 'Sorry, order events load failed, please try again.');
        this.setState({loadingSecondaryView: false});
      });
  }

  showEditDialog() {
    this.setState({
      showEditDialog: true,
    });
  }

  hideEditDialog() {
    this.setState({showEditDialog: false});
  }

  getActions(): TwoAction[] {
    const separator = {separator: true};
    const editAction = {
      icon: ['far', 'pencil'] as IconProp,
      label: 'Edit',
      main: true,
      action: () => {
        this.showEditDialog();
      },
    };

    const recordNoteAcion = {
      icon: ['far', 'sticky-note'] as IconProp,
      label: 'Record Note',
      main: false,
      action: () => {},
    };

    const sendEmailAction = {
      icon: ['far', 'envelope'] as IconProp,
      label: 'Send Email',
      main: false,
      action: () => {},
    };

    const deActivateAction = {
      icon: ['far', 'ban'] as IconProp,
      label: 'De-activate',
      main: false,
      action: () => {},
    };

    const disableAccessAction = {
      icon: ['far', 'user-slash'] as IconProp,
      label: 'Disable Access',
      main: false,
      action: () => {},
    };

    const blockEmailsAction = {
      icon: ['far', 'envelope'] as IconProp,
      label: 'Block Emails',
      main: false,
      action: () => {},
    };

    return [
      editAction,
      recordNoteAcion,
      sendEmailAction,
      separator,
      deActivateAction,
      disableAccessAction,
      blockEmailsAction,
    ];
  }

  render() {
    const {company, items} = this.state;

    if (company?.fit_for && !company?.fit_for[0]) {
      company.fit_for = [];
    }

    return company ? (
      <>
        <TwoEntityComponent title={company.name} actions={this.getActions()}>
          <TwoEntityPanel isPrimary={true}>
            {!this.state.loadingCompany ? <CompanyDetail company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Timeline" icon={['far', 'calendar-alt']} tooltip="Timeline">
            {!this.state.loadingSecondaryView ? <TwoTimeline key={company.id} items={items} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Contacts" icon={['far', 'user-friends']} tooltip="Contacts">
            {!this.state.loadingSecondaryView ? <CompanyContacts company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Locations" icon={['far', 'warehouse']} tooltip="Locations">
            {!this.state.loadingSecondaryView ? <CompanyLocations company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          <TwoEntityPanel label="Orders" icon={['far', 'list']} tooltip="Orders">
            {!this.state.loadingSecondaryView ? <CompanyOrders company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
          {company.is_distributor === true ? (
            <TwoEntityPanel label="SubDealers" icon={['far', 'building']} tooltip="Sub-Dealers">
              {!this.state.loadingSecondaryView ? <CompanySubDealers company={company} /> : <ProgressSpinner />}
            </TwoEntityPanel>
          ) : (
            <></>
          )}
          <TwoEntityPanel label="Fit for" icon={['far', 'building']} tooltip="Fit for">
            {!this.state.loadingSecondaryView ? <CompanyFitForComponent company={company} /> : <ProgressSpinner />}
          </TwoEntityPanel>
        </TwoEntityComponent>
        <EditCompanyDialog
          companyId={company.id!}
          showDialog={this.state.showEditDialog}
          onHide={this.hideEditDialog}
        />
        <Toast ref={this.toast} />
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(CompanyComponent);

import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, QueryParameter, Location, SharedLocation} from 'two-core';

export class SharedLocationsService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints.sharedLocations ?? '';
  }

  async createSharedLocation(data: Partial<SharedLocation>): Promise<SharedLocation> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as SharedLocation);
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  async deleteSharedLocation(id: number): Promise<SharedLocation> {
    return this.delete(`${this.endpoint}/${id}`)
      .then(data => {
        return Promise.resolve(data as SharedLocation);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

import React from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Company, User, State, Address, CompanyPatch, Location, SharedLocationPurpose} from 'two-core';
import {InputText} from 'primereact/inputtext';
import {companyStates, companyStages, businessEntityTypes, fittingTypes} from '../../config/values';
import {InputSwitch} from 'primereact/inputswitch';
import {Divider} from 'primereact/divider';
import {MultiSelect} from 'primereact/multiselect';
import {companyFreightOptions} from '../../utils/CompanyUtil';
import {Tooltip} from 'primereact/tooltip';

interface Props {
  company: CompanyPatch;
  users: User[];
  companies: Company[];
  onCompanyChange: (company: CompanyPatch) => void;
  isNewCompany?: boolean;
  pickUpLocations: Location[];
  storageLocations: Location[];
  selectedPickUpLocationIds: number[];
  selectedStorageLocationIds: number[];
  onSharedLocationsChange: (locationIds: number[], purpose: SharedLocationPurpose) => void;
}

const EditCompanyDialogContent = ({
  company,
  users,
  companies,
  onCompanyChange,
  isNewCompany,
  selectedPickUpLocationIds,
  selectedStorageLocationIds,
  pickUpLocations,
  storageLocations,
  onSharedLocationsChange,
}: Props) => {
  const onBillingAddressChange = (billingAddress: Partial<Address>) => {
    onCompanyChange({billing_address: {...company?.billing_address, ...billingAddress}});
  };

  let fitForTooltip;
  if (company.fit_for_ids) {
    fitForTooltip = (
      <Tooltip target={'#fit_for'}>
        <div>
          {companies
            .filter(c => company.fit_for_ids?.includes(c.id!))
            .map((c, i) => (
              <div key={i}>{c.name}</div>
            ))}
        </div>
      </Tooltip>
    );
  }

  let fittingProvidersTooltip;
  if (company.fitting_provider_ids) {
    fittingProvidersTooltip = (
      <Tooltip target={'#fiting_providers'}>
        <div>
          {companies
            .filter(c => company.fitting_provider_ids?.includes(c.id!))
            .map((c, i) => (
              <div key={i}>{c.name}</div>
            ))}
        </div>
      </Tooltip>
    );
  }
  let storageLocationsTooltip;
  if (selectedStorageLocationIds) {
    storageLocationsTooltip = (
      <Tooltip target={'#storage_locations'}>
        <div>
          {storageLocations
            .filter(l => selectedStorageLocationIds.includes(l.id!))
            .map((l, i) => (
              <div key={i}>{l.name}</div>
            ))}
        </div>
      </Tooltip>
    );
  }
  let pickupLocationsTooltip;
  if (selectedPickUpLocationIds) {
    pickupLocationsTooltip = (
      <Tooltip target={'#pickup_locations'}>
        <div>
          {pickUpLocations
            .filter(l => selectedPickUpLocationIds.includes(l.id!))
            .map((l, i) => (
              <div key={i}>{l.name}</div>
            ))}
        </div>
      </Tooltip>
    );
  }

  return (
    <>
      <div id="edit_company_dialog_content" className="p-fluid w-100">
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>name</small>
          </label>
          <div className="p-col-4">
            <InputText value={company?.name} onChange={e => onCompanyChange({name: e.target.value})} />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>trading as</small>
          </label>
          <div className="p-col-4">
            <InputText value={company?.trading_as} onChange={e => onCompanyChange({trading_as: e.target.value})} />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>stage</small>
          </label>
          <div className="p-col-4">
            <Dropdown
              filter
              options={companyStages}
              showClear
              value={company?.stage}
              onChange={e => onCompanyChange({stage: e.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>account number</small>
          </label>
          <div className="p-col-4">
            <InputText
              value={company?.account_number}
              onChange={e => onCompanyChange({account_number: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>business type</small>
          </label>
          <div className="p-col-4">
            <Dropdown
              value={company?.business_entity}
              options={businessEntityTypes}
              onChange={e => onCompanyChange({business_entity: e.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>tax id</small>
          </label>
          <div className="p-col-4">
            <InputText value={company?.tax_id} onChange={e => onCompanyChange({tax_id: e.target.value})} />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>phone number</small>
          </label>
          <div className="p-col-10">
            <InputText value={company?.phone_number} onChange={e => onCompanyChange({phone_number: e.target.value})} />
          </div>
        </div>
        <Divider />
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>billing address</small>
          </label>
          <div className="p-col-4">
            <InputText
              value={company?.billing_address?.street}
              onChange={e => onBillingAddressChange({street: e.target.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>suburb/city</small>
          </label>
          <div className="p-col-4">
            <InputText
              value={company?.billing_address?.suburb}
              onChange={e => onBillingAddressChange({suburb: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>state</small>
          </label>
          <div className="p-col-2">
            <InputText
              value={company?.billing_address?.state}
              onChange={e => onBillingAddressChange({state: e.target.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>country</small>
          </label>
          <div className="p-col-2">
            <InputText
              value={company?.billing_address?.country}
              onChange={e => onBillingAddressChange({country: e.target.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>postcode</small>
          </label>
          <div className="p-col-2">
            <InputText
              value={company?.billing_address?.postCode}
              onChange={e => onBillingAddressChange({postCode: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>accounts email</small>
          </label>
          <div className="p-col-10">
            <InputText
              value={company?.accounts_emails}
              onChange={e => onCompanyChange({accounts_emails: e.target.value})}
            />
          </div>
        </div>
        <Divider />
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>freight options</small>
          </label>
          <div className="p-col-10">
            <MultiSelect
              value={company.freight_options?.split(',')}
              options={companyFreightOptions}
              onChange={e => onCompanyChange({freight_options: e.value.length ? e.value.join(',') : undefined})}
              display="chip"
              tooltip={company.freight_options}
            />
          </div>
        </div>
        {!isNewCompany && (
          <div className="p-field p-grid">
            <label className="p-col-2 p-text-bold">
              <small>pick up locations</small>
            </label>
            <div className="p-col-10">
              {pickupLocationsTooltip}
              <MultiSelect
                value={selectedPickUpLocationIds}
                options={pickUpLocations}
                onChange={e => onSharedLocationsChange(e.value, 'Pick Up')}
                display="chip"
                optionLabel="name"
                optionValue="id"
                dataKey="id"
                filter
                id="pickup_locations"
              />
            </div>
          </div>
        )}
        {!isNewCompany && (
          <div className="p-field p-grid">
            <label className="p-col-2 p-text-bold">
              <small>storage locations</small>
            </label>
            <div className="p-col-10">
              {storageLocationsTooltip}
              <MultiSelect
                value={selectedStorageLocationIds}
                options={storageLocations}
                onChange={e => onSharedLocationsChange(e.value, 'Storage')}
                display="chip"
                optionLabel="name"
                optionValue="id"
                dataKey="id"
                filter
                id="storage_locations"
              />
            </div>
          </div>
        )}
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>delivery email</small>
          </label>
          <div className="p-col-10">
            <InputText
              value={company?.delivery_emails}
              onChange={e => onCompanyChange({delivery_emails: e.target.value})}
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>state</small>
          </label>
          <div className="p-col-4">
            <Dropdown
              value={company?.state}
              options={companyStates}
              onChange={e => onCompanyChange({state: e.value})}
            />
          </div>
          <label className="p-col-2 p-text-bold">
            <small>sales rep</small>
          </label>
          <div className="p-col-4">
            <Dropdown
              value={company?.sales_rep}
              options={users}
              onChange={e => onCompanyChange({sales_rep: e.value})}
              optionValue="id"
              optionLabel="full_name"
            />
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>quote</small>
          </label>
          <div className="p-col-1">
            <InputSwitch
              checked={company.does_quotes}
              onChange={e => {
                onCompanyChange({does_quotes: e.target.value});
              }}
            />
          </div>
          <label className="p-col-1 p-text-bold">
            <small>orders</small>
          </label>
          <div className="p-col-1">
            <InputSwitch
              checked={company.does_orders}
              onChange={e => {
                onCompanyChange({does_orders: e.target.value});
              }}
            />
          </div>
          <label className="p-col-1 p-text-bold">
            <small>is distributor</small>
          </label>
          <div className="p-col-1">
            <InputSwitch
              checked={company.is_distributor}
              onChange={e => {
                onCompanyChange({is_distributor: e.target.value});
              }}
            />
          </div>
          <label className="p-col-1 p-text-bold">
            <small>{company.is_distributor ? 'key' : 'parent'}</small>
          </label>
          <div className="p-col-4">
            {company.is_distributor ? (
              <InputText
                value={company?.distributor_key}
                onChange={e => onCompanyChange({distributor_key: e.target.value})}
              />
            ) : (
              <Dropdown
                filter
                options={companies}
                showClear
                value={company?.parent_id}
                onChange={e => onCompanyChange({parent_id: e.value})}
                optionLabel="name"
                optionValue="id"
                dataKey="id"
              />
            )}
          </div>
        </div>
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>fitting types</small>
          </label>
          <div className="p-col-10">
            <MultiSelect
              value={company.fitting_types?.split(',')}
              options={fittingTypes}
              onChange={e => onCompanyChange({fitting_types: e.value.length ? e.value.join(',') : undefined})}
              display="chip"
              tooltip={company.fitting_types}
            />
          </div>
        </div>
        {!isNewCompany && (
          <div className="p-field p-grid">
            <label className="p-col-2 p-text-bold">
              <small>fitting providers</small>
            </label>
            <div className="p-col-10">
              {fittingProvidersTooltip}
              <MultiSelect
                value={company.fitting_provider_ids}
                options={companies}
                onChange={e => onCompanyChange({fitting_provider_ids: e.value})}
                display="chip"
                optionLabel="name"
                optionValue="id"
                dataKey="id"
                filter
                id="fiting_providers"
              />
            </div>
          </div>
        )}
        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>fit for others</small>
          </label>
          <div className="p-col-10">
            <InputSwitch
              checked={company.fits_for_others}
              onChange={e => {
                onCompanyChange({fits_for_others: e.target.value});
              }}
            />
          </div>
        </div>
        {company.fits_for_others && !isNewCompany && (
          <div className="p-field p-grid">
            <label className="p-col-2 p-text-bold">
              <small>fit for</small>
            </label>
            <div className="p-col-10">
              {fitForTooltip}
              <MultiSelect
                value={company.fit_for_ids}
                options={companies}
                onChange={e => onCompanyChange({fit_for_ids: e.value})}
                display="chip"
                optionLabel="name"
                optionValue="id"
                dataKey="id"
                filter
                id="fit_for"
              />
            </div>
          </div>
        )}

        <div className="p-field p-grid">
          <label className="p-col-2 p-text-bold">
            <small>external id</small>
          </label>
          <div className="p-col-10">
            <InputText value={company?.external_id} onChange={e => onCompanyChange({external_id: e.target.value})} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCompanyDialogContent;

import {Chip} from 'primereact/chip';
import React from 'react';
import {Company} from 'two-core';
import {InputSwitch} from 'primereact/inputswitch';
import {EntityDetailDivider, EntityDetailField} from 'two-app-ui';
import {Tooltip} from 'primereact/tooltip';

interface Props {
  company: Company;
}

class CompanyDetail extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const company = this.props.company;
    let billingAddress;
    if (company.billing_address) {
      billingAddress = `${company.billing_address.country ?? ''}, ${company.billing_address.street ?? ''}, ${
        company.billing_address.suburb ?? ''
      }, ${company.billing_address.state ?? ''}`;
    }
    const accountsEmailChips = company.accounts_emails
      ?.split(',')
      .map((email, i) => <Chip key={i} className="p-fluid p-mr-1 p-mb-1" label={email} />);
    const freightOptionChips = company.freight_options
      ?.split(',')
      .map((option, i) => <Chip key={i} className="p-fluid p-mr-1 p-mb-1" label={option} />);
    const deliveryEmailChips = company.delivery_emails
      ?.split(',')
      .map((email, i) => <Chip key={i} className="p-fluid p-mr-1 p-mb-1" label={email} />);
    let parentName = company.parent_company?.name;
    if (company.parent_company?.trading_as) {
      parentName += ` (${company.parent_company.trading_as})`;
    }
    const fittingTypeChips = company.fitting_types
      ?.split(',')
      .map((type, i) => <Chip key={i} className="p-fluid p-mr-1 pmb-1" label={type} />);
    const fittingProviderChips = company.fitting_providers?.map((provider, i) => (
      <>
        <Tooltip target={`.freight-provider-chip-${provider.id}`}>test</Tooltip>
        <Chip
          key={i}
          className={`p-fluid p-mr-1 pmb-1 freight-provider-chip-${provider.id}`}
          label={provider.account_number}
        />
      </>
    ));
    let fitFor = <div>0</div>;
    if (company.fit_for?.length) {
      fitFor = (
        <>
          <Tooltip target={'#fit_for'}>
            {company.fit_for?.map((fitForCompany, i) => {
              let label = fitForCompany.name;
              if (fitForCompany.trading_as) {
                label += ` (${fitForCompany.trading_as})`;
              }
              return <div key={i}>{label}</div>;
            })}
          </Tooltip>
          <span id="fit_for">{company.fit_for.length}</span>
        </>
      );
    }

    const pickupSharedLocations = company.shared_locations?.filter(location => location.purpose === 'Pick Up');
    const storageSharedLocations = company.shared_locations?.filter(location => location.purpose === 'Storage');
    const pickupSharedLocationChips = [];
    const storageSharedLocationChips = [];
    if (pickupSharedLocations?.length) {
      for (const pickupSharedLocation of pickupSharedLocations) {
        const location = company.shared_location_locations?.find(l => l.id === pickupSharedLocation.location_id);
        pickupSharedLocationChips.push(
          <Chip key={pickupSharedLocation.id} className="p-fluid p-mr-1 p-mb-1" label={location?.name} />
        );
      }
    }
    if (storageSharedLocations?.length) {
      for (const storageSharedLocation of storageSharedLocations) {
        const location = company.shared_location_locations?.find(l => l.id === storageSharedLocation.location_id);
        storageSharedLocationChips.push(
          <Chip key={storageSharedLocation.id} className="p-fluid p-mr-1 p-mb-1" label={location?.name} />
        );
      }
    }

    return (
      <>
        <EntityDetailField label={'stage'}>{company.stage}</EntityDetailField>
        <EntityDetailField label={'trading as'}>{company.trading_as}</EntityDetailField>
        <EntityDetailField label={'acc number'}>{company.account_number}</EntityDetailField>
        <EntityDetailField label={'entity type'}>{company.business_entity}</EntityDetailField>
        <EntityDetailField label={'tax id'}>{company.tax_id}</EntityDetailField>
        <EntityDetailField label={'phone'}>{company.phone_number}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label={'billing address'}>{billingAddress}</EntityDetailField>
        <EntityDetailField label={'accounts emails'}>{accountsEmailChips}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label={'freight options'}>{freightOptionChips}</EntityDetailField>
        <EntityDetailField label={'pickup locations'}>{pickupSharedLocationChips}</EntityDetailField>
        <EntityDetailField label={'storage locations'}>{storageSharedLocationChips}</EntityDetailField>
        <EntityDetailField label={'delivery emails'}>{deliveryEmailChips}</EntityDetailField>
        <EntityDetailDivider />
        <div className="p-grid">
          <label className="p-col-12 p-md-3">state</label>
          <div className="p-col-12 p-md-3">{company.state}</div>
          <label className="p-col-12 p-md-3">rep</label>
          <div className="p-col-12 p-md-3">{company.sales_rep_user?.full_name}</div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-3">quotes</label>
          <div className="p-col-12 p-md-3">
            <InputSwitch checked={company.does_quotes} />
          </div>
          <label className="p-col-12 p-md-3">orders</label>
          <div className="p-col-12 p-md-3">
            <InputSwitch checked={company.does_orders} />
          </div>
        </div>
        <div className="p-grid">
          <label className="p-col-12 p-md-3">is distributor</label>
          <div className="p-col-12 p-md-3">
            <InputSwitch checked={company.is_distributor} />
          </div>
          <label className="p-col-12 p-md-3">key</label>
          <div className="p-col-12 p-md-3">
            {company.is_distributor ? company.distributor_key : company.parent_company?.distributor_key}
          </div>
        </div>
        <EntityDetailField label={'parent'}>{parentName}</EntityDetailField>
        <EntityDetailDivider />
        <EntityDetailField label={'fitting types'}>{fittingTypeChips}</EntityDetailField>
        <EntityDetailField label={'fitting providers'}>{fittingProviderChips}</EntityDetailField>
        <div className="p-grid">
          <label className="p-col-12 p-md-3">fits for others</label>
          <div className="p-col-12 p-md-3">
            <InputSwitch checked={company.fits_for_others} />
          </div>
          <label className="p-col-12 p-md-3">for</label>
          <div className="p-col-12 p-md-3">{fitFor}</div>
        </div>
        <EntityDetailDivider />
        <EntityDetailField label={'external id'}>{company.external_id}</EntityDetailField>
      </>
    );
  }
}

export default CompanyDetail;

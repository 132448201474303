import React from 'react';
import {AppContext, MessageService, TwoDialog, TwoToast, UsersService} from 'two-app-ui';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Subscription} from 'rxjs';
import {
  Address,
  Company,
  CompanyAggregate,
  CompanyPatch,
  Location,
  QueryParameter,
  SharedLocation,
  SharedLocationPurpose,
  User,
} from 'two-core';
import EditCompanyDialogContent from './EditCompanyDialogContent';
import {DropdownChangeParams} from 'primereact/dropdown';
import {messages} from '../../config/messages';
import CompaniesService from '../../services/CompaniesService';
import {MultiSelectChangeParams} from 'primereact/multiselect';
import LocationsService from '../../services/LocationsService';
import {SharedLocationsService} from '../../services/SharedLocationsService';

interface Props {
  companyId?: string;
  showDialog: boolean;
  onHide: () => void;
  isNewCompany?: boolean;
}

interface State {
  loadingCompany: boolean;
  loadingUsers: boolean;
  loadingParentCompanies: boolean;
  loadingAllCompanies: boolean;
  loadingLocations: boolean;
  saving: boolean;
  company?: Company;
  companyPatch: CompanyPatch;
  parentCompanies: Company[];
  users: User[];
  allCompanies: Company[];
  storageLocations: Location[];
  pickUpLocations: Location[];
  sharedLocationToRemove: SharedLocation[];
  sharedLocationsToAdd: Partial<SharedLocation>[];
}

class EditCompanyDialog extends React.Component<Props, State> {
  static contextType = AppContext;
  companiesService?: CompaniesService;
  locationsService?: LocationsService;
  sharedLocationsService?: SharedLocationsService;
  twoToast?: TwoToast;
  usersService?: UsersService;

  messageSendSubscription: Subscription = new Subscription();

  constructor(props: Props) {
    super(props);
    this.state = {
      loadingCompany: false,
      loadingUsers: false,
      loadingAllCompanies: false,
      loadingParentCompanies: false,
      loadingLocations: false,
      saving: false,
      companyPatch: {},
      parentCompanies: [],
      users: [],
      allCompanies: [],
      storageLocations: [],
      pickUpLocations: [],
      sharedLocationToRemove: [],
      sharedLocationsToAdd: [],
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.handleFitForChange = this.handleFitForChange.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onSharedLocationsChange = this.onSharedLocationsChange.bind(this);
    this.onShow = this.onShow.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.companiesService = this.context.companiesService;
    this.locationsService = this.context.locationsService;
    this.sharedLocationsService = this.context.sharedLocationsService;
    this.usersService = this.context.usersService;
    this.twoToast = this.context.twoToast;
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.messageSendSubscription.unsubscribe();
  }

  async loadData() {
    const {companyId} = this.props;
    let company: Company | undefined;
    if (companyId) {
      company = await this.loadCompany(companyId);
    }
    const users = await this.loadUsers();
    const parentCompanies = await this.loadParentCompanies();
    const allCompanies = await this.loadAllCompanies();
    const locationsResult = await this.loadLocations();
    this.setState({
      company,
      users: users ?? [],
      parentCompanies: parentCompanies ?? [],
      allCompanies: allCompanies ?? [],
      storageLocations: locationsResult?.storageLocations ?? [],
      pickUpLocations: locationsResult?.pickUpLocations ?? [],
    });
  }

  async loadCompany(id: string) {
    this.setState(() => ({loadingCompany: true}));
    const filters: string[] = [];
    filters.push(JSON.stringify({field: 'id', value: id}));
    const companyAggregate: CompanyAggregate[] = [
      'sales_rep_user',
      'parent_company',
      'contacts',
      'company_contacts',
      'fit_for',
      'shared_locations',
      'shared_location_locations',
      'fitting_providers',
    ];

    const params: QueryParameter = {filters, aggregate: companyAggregate};
    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        const companies = (data?.records as Company[]) ?? [];
        return companies?.[0];
      })
      .catch(error => {
        this.twoToast?.showError('Failed loading dealerships, please refresh and try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState(() => ({loadingCompany: false})));
  }

  async loadUsers() {
    this.setState(() => ({loadingUsers: true}));
    const params: QueryParameter = {
      filters: [],
      aggregate: false,
    };

    //TODO: add filter to load only sales reps => users with role sales_rep in made2manage

    return this.usersService
      ?.getUsers(params)
      .then(data => {
        return data.records as User[];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, user records load failed, please try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState({loadingUsers: false}));
  }

  async loadParentCompanies() {
    this.setState(() => ({loadingParentCompanies: true}));
    const params: QueryParameter = {
      filters: [
        JSON.stringify({
          field: 'is_distributor',
          value: true,
        }),
      ],
      aggregate: false,
    };
    return this.companiesService
      ?.getCompanies(params)
      .then(data => {
        return data.records as Company[];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, parent company records load failed, please try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState({loadingParentCompanies: false}));
  }
  async loadAllCompanies() {
    this.setState(() => ({loadingAllCompanies: true}));
    return this.companiesService
      ?.getCompanies({
        aggregate: false,
        orderBys: [JSON.stringify({field: 'name', direction: 'ASC'})],
      })
      .then(data => {
        return data.records as Company[];
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, company records load failed, please try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState({loadingAllCompanies: false}));
  }

  async loadLocations() {
    this.setState(() => ({loadingLocations: true}));
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        orConditions: [
          {
            field: 'used_for_storage',
            value: true,
          },
          {
            field: 'used_for_pickup',
            value: true,
          },
        ],
      })
    );
    return this.locationsService
      ?.getLocations({
        filters,
        aggregate: false,
        orderBys: [JSON.stringify({field: 'name', direction: 'ASC'})],
      })
      .then(data => {
        const locations = data.records as Location[];
        const storageLocations = locations.filter(l => l.used_for_storage);
        const pickUpLocations = locations.filter(l => l.used_for_pickup);

        return {storageLocations, pickUpLocations};
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, location records load failed, please try again.');
        console.error(error);
        return undefined;
      })
      .finally(() => this.setState(() => ({loadingLocations: false})));
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {companyPatch} = this.state;
    let value = e.target.value;
    const name = e.target.name;
    if (e.target.name === 'fitting_types') {
      value = e.target.value && e.target.value.length > 0 ? e.target.value.join(',') : '';
    }
    const updatedCompany = {
      ...companyPatch,
      [name]: value,
    };
    this.setState({companyPatch: updatedCompany});
  }

  onCompanyChange(data: CompanyPatch) {
    this.setState(state => ({companyPatch: {...state.companyPatch, ...data}}));
  }

  handleFitForChange(e: MultiSelectChangeParams) {
    const updatedCompany = {
      ...this.state.companyPatch,
      fit_for_ids: e.target.value,
    };
    this.setState({
      companyPatch: updatedCompany,
    });
  }

  setBillingAddress(e: React.ChangeEvent<HTMLInputElement> | DropdownChangeParams) {
    const {company, companyPatch} = this.state;
    const address: Address = companyPatch?.billing_address ??
      company?.billing_address ?? {
        state: '',
        street: '',
        state_short: '',
        suburb: '',
        postCode: '',
        country: '',
        phoneNumber: '',
        lat: 0,
        long: 0,
      };

    const updatedAddress: Address = {
      ...address,
      [e.target.name]: e.target.value,
    };

    const updatedCompany = {
      ...companyPatch,
      billing_address: updatedAddress,
    };
    this.setState({companyPatch: updatedCompany});
  }

  onSave = () => {
    const {isNewCompany} = this.props;
    const {companyPatch, company, sharedLocationsToAdd, sharedLocationToRemove} = this.state;
    if (this.validate()) {
      if (isNewCompany) {
        this.createCompany(companyPatch);
      } else {
        this.updateCompany(company!.id!, companyPatch, sharedLocationsToAdd, sharedLocationToRemove);
      }
    }
  };

  async updateCompany(
    id: string,
    companyPatch: CompanyPatch,
    sharedLocationsToAdd: Partial<SharedLocation>[],
    sharedLocationToRemove: SharedLocation[]
  ) {
    this.setState({saving: true});
    try {
      if (Object.keys(companyPatch).length) {
        await this.companiesService?.updateCompany(id, companyPatch);
      }
      if (sharedLocationsToAdd.length || sharedLocationToRemove.length) {
        await this.updateSharedLocations(sharedLocationsToAdd, sharedLocationToRemove);
      }
      MessageService.sendMessage(messages.companyUpdated);
      this.twoToast?.showSuccess('Company updated successfully.');
      this.onHide();
    } catch (e) {
      this.twoToast?.showError(
        `Sorry, ${this.state.company?.trading_as ?? this.state.company?.name} update failed, please try again.`
      );
      console.error(e);
      this.setState({saving: false});
    }
  }

  updateSharedLocations(sharedLocationsToAdd: Partial<SharedLocation>[], sharedLocationToRemove: SharedLocation[]) {
    const addPromises = sharedLocationsToAdd.map(sl => this.sharedLocationsService?.createSharedLocation(sl));
    const removePromises = sharedLocationToRemove.map(sl => this.sharedLocationsService?.deleteSharedLocation(sl.id!));
    return Promise.all([...addPromises, ...removePromises]);
  }

  async createCompany(company: CompanyPatch) {
    this.setState({saving: true});
    return this.companiesService
      ?.createCompany(company)
      .then(() => {
        this.twoToast?.showSuccess('Company created successfully.');
        this.onHide();
      })
      .catch(error => {
        this.twoToast?.showError('Sorry, Company create failed, please try again.');
        console.error('error: ' + error);
        this.setState({saving: false});
      });
  }

  onHide() {
    this.setState({
      saving: false,
      loadingAllCompanies: false,
      loadingCompany: false,
      loadingLocations: false,
      loadingParentCompanies: false,
      loadingUsers: false,
      company: undefined,
      companyPatch: {},
      sharedLocationToRemove: [],
      sharedLocationsToAdd: [],
    });
    this.props.onHide();
  }

  onShow() {
    this.loadData();
  }

  validate() {
    const company = {
      ...this.state.company,
      fit_for_ids: this.state.company?.fit_for?.map(c => c.id),
      fitting_provider_ids: this.state.company?.fitting_providers?.map(c => c.id),
      ...this.state.companyPatch,
    } as CompanyPatch;
    const messages: string[] = [];
    if (!company.name) {
      messages.push('Name is required.');
    }
    if (!company.stage) {
      messages.push('Stage is required.');
    }
    if (messages.length > 0) {
      this.twoToast?.showError(
        <ul>
          {messages.map((m, i) => (
            <li key={i}>{m}</li>
          ))}
        </ul>
      );
      return false;
    }
    return true;
  }

  onSharedLocationsChange(locationIds: number[], purpose: SharedLocationPurpose) {
    const {sharedLocationsToAdd, sharedLocationToRemove, company} = this.state;
    const samePurposeSharedLocations = company?.shared_locations?.filter(sl => sl.purpose === purpose) ?? [];
    const newSharedLocationsToAdd = sharedLocationsToAdd.filter(sl => sl.purpose !== purpose);
    const newSharedLocationToRemove = sharedLocationToRemove.filter(sl => sl.purpose !== purpose);
    for (const locationId of locationIds) {
      const sharedLocation = samePurposeSharedLocations.find(sl => sl.location_id === locationId);
      if (!sharedLocation) {
        newSharedLocationsToAdd.push({
          company_id: company?.id ?? '',
          location_id: locationId,
          purpose: purpose,
        });
      }
    }
    for (const sharedLocation of samePurposeSharedLocations) {
      if (!locationIds.includes(sharedLocation.location_id)) {
        newSharedLocationToRemove.push(sharedLocation);
      }
    }
    this.setState({
      sharedLocationsToAdd: newSharedLocationsToAdd,
      sharedLocationToRemove: newSharedLocationToRemove,
    });
  }

  render() {
    const {isNewCompany} = this.props;
    const {
      pickUpLocations,
      storageLocations,
      sharedLocationsToAdd,
      sharedLocationToRemove,
      loadingLocations,
      loadingUsers,
      loadingParentCompanies,
      loadingAllCompanies,
      loadingCompany,
      saving,
      users,
      allCompanies,
    } = this.state;
    const company = {
      ...this.state.company,
      fit_for_ids: this.state.company?.fit_for?.map(c => c.id),
      fitting_provider_ids: this.state.company?.fitting_providers?.map(c => c.id),
      ...this.state.companyPatch,
    } as CompanyPatch;
    const header = this.state.company
      ? `Edit ${this.state.company.trading_as ?? this.state.company.name}`
      : 'Create Company';
    const removedSharedLocationIds = sharedLocationToRemove.map(sl => sl.id);
    const selectedSharedLocations: Partial<SharedLocation>[] =
      this.state.company?.shared_locations?.filter(sl => !removedSharedLocationIds.includes(sl.id)) ?? [];
    for (const sharedLocationToAdd of sharedLocationsToAdd) {
      selectedSharedLocations.push(sharedLocationToAdd);
    }
    const selectedPickUpLocationIds = selectedSharedLocations
      .filter(sl => sl.purpose === 'Pick Up')
      .map(sl => sl.location_id!);
    const selectedStorageLocationIds = selectedSharedLocations
      .filter(sl => sl.purpose === 'Storage')
      .map(sl => sl.location_id!);
    const loading = loadingCompany || loadingUsers || loadingParentCompanies || loadingAllCompanies || loadingLocations;
    return (
      <>
        <TwoDialog
          header={header}
          showDialog={this.props.showDialog}
          className="p-fluid p-col-11 p-lg-8"
          onHide={this.onHide}
          onShow={this.onShow}
          onSave={this.onSave}
          loading={loading}
          saving={saving}
        >
          <EditCompanyDialogContent
            company={company}
            onCompanyChange={this.onCompanyChange}
            users={users}
            companies={allCompanies}
            isNewCompany={isNewCompany}
            pickUpLocations={pickUpLocations}
            storageLocations={storageLocations}
            selectedPickUpLocationIds={selectedPickUpLocationIds}
            selectedStorageLocationIds={selectedStorageLocationIds}
            onSharedLocationsChange={this.onSharedLocationsChange}
          />
        </TwoDialog>
      </>
    );
  }
}

export default EditCompanyDialog;
